/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ContentItemDto } from '../models/content-item-dto';
import { DownloadCriteriaDto } from '../models/download-criteria-dto';

@Injectable({
  providedIn: 'root',
})
export class AzureBlobStorageService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation downloadSelectedContentItems
   */
  static readonly DownloadSelectedContentItemsPath = '/api/blobStorage/DownloadContentItems';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadSelectedContentItems()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  downloadSelectedContentItems$Response(params?: {
    userId?: null | string;
      body?: DownloadCriteriaDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AzureBlobStorageService.DownloadSelectedContentItemsPath, 'post');
    if (params) {

      rb.query('userId', params.userId);

      rb.body(params.body, 'application/*+json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadSelectedContentItems$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  downloadSelectedContentItems(params?: {
    userId?: null | string;
      body?: DownloadCriteriaDto
  }): Observable<void> {

    return this.downloadSelectedContentItems$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getDownloadUrl
   */
  static readonly GetDownloadUrlPath = '/api/blobStorage/GetDownloadUrl';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDownloadUrl()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDownloadUrl$Response(params?: {
    userId?: null | string;

  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, AzureBlobStorageService.GetDownloadUrlPath, 'get');
    if (params) {

      rb.query('userId', params.userId);

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDownloadUrl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDownloadUrl(params?: {
    userId?: null | string;

  }): Observable<string> {

    return this.getDownloadUrl$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation uploadFile
   */
  static readonly UploadFilePath = '/api/blobStorage/UploadFiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadFile$Response(params?: {
    revitYears?: null | string;
    userName?: null | string;
      body?: { 'files'?: Array<Blob> }
  }): Observable<StrictHttpResponse<Array<ContentItemDto>>> {

    const rb = new RequestBuilder(this.rootUrl, AzureBlobStorageService.UploadFilePath, 'post');
    if (params) {

      rb.query('revitYears', params.revitYears);
      rb.query('userName', params.userName);

      rb.body(params.body, 'multipart/form-data');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ContentItemDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadFile(params?: {
    revitYears?: null | string;
    userName?: null | string;
      body?: { 'files'?: Array<Blob> }
  }): Observable<Array<ContentItemDto>> {

    return this.uploadFile$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ContentItemDto>>) => r.body as Array<ContentItemDto>)
    );
  }

  /**
   * Path part for operation cleanupTempFolder
   */
  static readonly CleanupTempFolderPath = '/api/blobStorage/CleanupTempFolder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cleanupTempFolder()` instead.
   *
   * This method doesn't expect any request body.
   */
  cleanupTempFolder$Response(params?: {
    revitYears?: null | string;
    userName?: null | string;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AzureBlobStorageService.CleanupTempFolderPath, 'get');
    if (params) {

      rb.query('revitYears', params.revitYears);
      rb.query('userName', params.userName);

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cleanupTempFolder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cleanupTempFolder(params?: {
    revitYears?: null | string;
    userName?: null | string;

  }): Observable<void> {

    return this.cleanupTempFolder$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getUploadedFiles
   */
  static readonly GetUploadedFilesPath = '/api/blobStorage/GetUploadedFiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUploadedFiles()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getUploadedFiles$Response(params?: {
      body?: null | Array<string>
  }): Observable<StrictHttpResponse<Array<ContentItemDto>>> {

    const rb = new RequestBuilder(this.rootUrl, AzureBlobStorageService.GetUploadedFilesPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ContentItemDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUploadedFiles$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getUploadedFiles(params?: {
      body?: null | Array<string>
  }): Observable<Array<ContentItemDto>> {

    return this.getUploadedFiles$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ContentItemDto>>) => r.body as Array<ContentItemDto>)
    );
  }

}

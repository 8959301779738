<mat-form-field *ngIf="hasFilter" style="margin-bottom: 10px;">
    <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
</mat-form-field>

<ng-container>
    <dropdown-select class="dropdown-container" [showDropdown]="showDropdown" [downloadOption]="downloadOption" [uploadOption]="uploadOption" [selectedContentItems]="onDownload()" (resetSelection)='resetSelection()'>
    </dropdown-select>
</ng-container>


<mat-progress-bar *ngIf="showProgressBar" mode="determinate" value="{{currentProgressForProgressBar}}">
</mat-progress-bar>

<mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions">
</mat-paginator>
<div class="expand-table-content">
    <table summary="Mat Table with multiple selection" multiTemplateDataRows [shiftClickSource]="tableData" [shiftClickSelectModel]="selection" [shiftClickSourceId]="['position']" mat-table (matSortChange)="sortData($event)" [dataSource]="tableData" class="fixed-header-table"
        matSort>

        <ng-container [matColumnDef]="tableData" *ngFor="let tableData of objectKeys(tableHeader); let i = index;">
            <span *ngIf="i === 0">
        <th id="header" mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? toggleAll() : null"
            [checked]="selection.hasValue() && isAllSelected() || !isAllDeselected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? onCheckboxAction($event,row) : null"
            [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </span>

            <span *ngIf=" i !==0">
        <th id="header" mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition='after'>
          {{tableHeader[tableData]}} </th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="tableHeader[tableData] === 'Upload Progress'">
            <div class="progress">
              <div *ngIf="element[tableData] === 'Succeeded'" class="progress-bar progress-bar-striped bg-success"
                role="progressbar" [style.width.%]="100">{{element[tableData]}}</div>
              <div *ngIf="element[tableData] === 'Uploading'" class="progress-bar progress-bar-striped active"
                role="progressbar" [style.width.%]="100">
              </div>
              <div *ngIf="element[tableData] === 'Failed'" class="progress-bar progress-bar-striped bg-danger"
                role="progressbar" [style.width.%]="100">{{element[tableData]}}</div>
            </div>
          </div>
          <div *ngIf="tableHeader[tableData] === 'Image'; then thenBlock else elseBlock"></div>
          <ng-template #thenBlock>
            <img [attr.src]="imageConverter.getImage(element[tableData])" width="50px" height="50px" alt="image.png">
          </ng-template>
          <ng-template #elseBlock>
            <div *ngIf="tableHeader[tableData] !== 'Upload Progress'">
              {{element[tableData]}}
            </div>
          </ng-template>
        </td>
      </span>
        </ng-container>

        <ng-container *ngIf="uploadOption" matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="objectKeys(tableHeader).length">
                <div class="example-element-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
                    <div *ngFor="let tableData of objectKeys(tableHeader); let i = index;">
                        <div *ngIf="element[tableData] === 0" class="example-element-description">
                            <p style="font-size: 14px;" class="fa fa-exclamation-triangle" aria-hidden="true"> Error 404: The file was not found on azure.</p>
                        </div>
                    </div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="objectKeys(tableHeader);sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: objectKeys(tableHeader)" [ngClass]="{hovered: row.hovered}" (mouseover)="row.hovered = true" (mouseout)="row.hovered = false" class="example-element-row" [class.example-expanded-row]="expandedElement === row"
            (click)="selection.toggle(row)" (contextmenu)="(hasContextMenu(row))&& onContextMenu($event, row)">
            >
        </tr>
        <div *ngIf="uploadOption">
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </div>
    </table>

</div>
